// extracted by mini-css-extract-plugin
export var aboutTopImage = "about-module--aboutTopImage--cb722";
export var col1 = "about-module--col1--04124";
export var container = "about-module--container--f9591";
export var count = "about-module--count--cd433";
export var countriesTraveled = "about-module--countriesTraveled--9b414";
export var firstSection = "about-module--firstSection--1810f";
export var gridRow = "about-module--gridRow--b3963";
export var headerDesc = "about-module--headerDesc--24efb";
export var profilePic = "about-module--profilePic--2ac61";
export var screenReaderText = "about-module--screenReaderText--f6cee";
export var section = "about-module--section--f4826";
export var sectionHeader = "about-module--sectionHeader--7f542";
export var totalCountries = "about-module--totalCountries--1b235";
export var wrap = "about-module--wrap--fe8bc";